import React from 'react';
import ReactDOM from 'react-dom';

export default class Temoignages extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            temoignages:this.props.tem,
            count: this.props.tem.length,
            nb:1,
        }
        this.handlePrev = this.handlePrev.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handlePoint = this.handlePoint.bind(this);
    }

    componentDidMount() {
        let {count} = this.state;
        let point = document.querySelector("#point");
        let wwPoint = (count * 28);
        point.style.width = wwPoint+"px";
    }
    handlePrev(){
        let {nb} = this.state;
        if(nb > 1){
            let fullPoint = document.querySelectorAll('.count');
            fullPoint.forEach(value => {
                value.classList.remove('active');
            });
            let current = document.querySelector('#blockView-'+nb);
            nb--;
            let point = document.querySelector('#count-'+nb);
            point.classList.add('active');
            let prev = document.querySelector('#blockView-'+nb);
            current.classList.add('animate__animated','animate__fadeOutRight');
            setTimeout(() => {
                current.classList.remove('active');
                current.classList.remove('animate__animated','animate__fadeOutRight');
            },500)

            setTimeout(() => {
                prev.classList.add('active');
                prev.classList.add('active','animate__animated','animate__fadeInLeft');
                setTimeout(() => {
                    prev.classList.remove('animate__animated','animate__fadeInLeft');
                },1000)
            },500)

            this.setState({nb});
        }
    }
    handleNext(){
        let {nb,count} = this.state;
        if(count > nb){
            let fullPoint = document.querySelectorAll('.count');
            fullPoint.forEach(value => {
                value.classList.remove('active');
            });
            let current = document.querySelector('#blockView-'+nb);
            nb++;
            let point = document.querySelector('#count-'+nb);
            point.classList.add('active');
            let prev = document.querySelector('#blockView-'+nb);

            current.classList.add('animate__animated','animate__fadeOutLeft');
            setTimeout(() => {
                current.classList.remove('active');
                current.classList.remove('animate__animated','animate__fadeOutLeft');
            },500)



            setTimeout(() => {
                prev.classList.add('active');
                prev.classList.add('active','animate__animated','animate__fadeInRight');
                setTimeout(() => {
                    prev.classList.remove('animate__animated','animate__fadeInRight');
                },1000)
            },500)



            this.setState({nb});
        }
    }
    handlePoint(index,target){
        let fullPoint = document.querySelectorAll('.count');
        let fullItem = document.querySelectorAll('.listTem');
        let oldItem = document.querySelector('.listTem.active');
        fullItem.forEach(value => {
            oldItem.classList.add('animate__animated','animate__fadeOut');
            setTimeout(() => {
            value.classList.remove('active');
                oldItem.classList.remove('animate__animated','animate__fadeOut');
            },500)
        });
        fullPoint.forEach(value => {
            value.classList.remove('active');
        })
        let nb = index+1;
        let current = document.querySelector('#blockView-'+nb);


        setTimeout(() => {
            target.classList.add("active");
            current.classList.add('active','animate__animated','animate__fadeIn');
            setTimeout(() => {
                current.classList.remove('animate__animated','animate__fadeIn');
            },1000)
        },500)
        this.setState({nb});
    }
    render() {
        let {temoignages,nb,count} = this.state;
        return (
            <>
            <div id="blockTem" className="blockTem">
            {
                temoignages.map((value,index) => (
                    <div className={index === 0 ? "listTem active" : "listTem"} key={index} id={"blockView-"+(index + 1)}>
                        <div className="image">
                            <img src={"/public/storage/"+value.image} alt={value.Nom}/>
                        </div>
                        <div className="text">
                            <div className="quote"><img width="61" height="40" src="/public/images/quote.svg" alt="quote"/></div>
                            <div className="temoignage" dangerouslySetInnerHTML={{__html: value.body}}></div>
                            <div className="personne">
                                <p className="name">{value.Nom}</p>
                                <p className="metier">{value.profession}</p>
                            </div>
                        </div>
                    </div>
                ))
            }
            </div>
            <div className="diaporamaEffet">
                <div className="point" id="point">
                    {
                        temoignages.map((value,index) => (
                            <div className={index === 0 ? "count active" : "count"}  key={index} id={"count-"+(index + 1)} onClick={(e) => this.handlePoint(index,e.target)}></div>
                        ))
                    }
                </div>
                <div className="arrow">
                    <div className={nb !== 1 ? "prev active" : "prev"} onClick={this.handlePrev}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#7f7f7f" width="30" height="30" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"/></svg>
                    </div>
                    <div className={nb !== count ? "next active" : "next"} onClick={this.handleNext}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#7f7f7f" width="30" height="30" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"/></svg>
                    </div>
                </div>
            </div>
            </>
        )
    }
}
if (document.getElementById('temoignages')) {
    let tem = $('#temoignages').data('tem');
    ReactDOM.render(<Temoignages  tem={tem} />, document.getElementById('temoignages'));
}
