import React from 'react';
import ReactDOM from 'react-dom';
import Moment from 'react-moment';
import 'moment-timezone';

export default class FormationSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fiches: this.props.fiches,
            fiche: null,
            metier: this.props.metier,
            diplome: Object.values(this.props.diplome),
            dipSelect: null,
            niveau: Object.values(this.props.niveau),
            niveauView: true,
            diplomeView: false,
            ficheView: false,
            doubleFiche:false,
            nameNiv: null,
            nameDip: null,

        }
        this.handleSelectNiveau = this.handleSelectNiveau.bind(this);
        this.handleSelectDiplome = this.handleSelectDiplome.bind(this);
        this.handleReturnNiveau = this.handleReturnNiveau.bind(this);
        this.handleReturnDip = this.handleReturnDip.bind(this);
        this.handleReturnMetier = this.handleReturnMetier.bind(this);
        this.handleSelectDiplome2 = this.handleSelectDiplome2.bind(this);
        this.breadCrumb = this.breadCrumb.bind(this);
    }

    componentDidMount() {
        const {metier} = this.state;
        let banner = document.querySelector("#banner img");
        banner.setAttribute('src', "/public/storage/" + metier.icon);
        this.breadCrumb()
    }

    breadCrumb() {
        let title = document.querySelector('#titre');
        let breadcrumb = document.querySelector('#breadcrumb');
        let bc = document.querySelector('#bc');

        title.textContent = this.state.metier.nom;
        if (this.state.fiches !== null) {
            breadcrumb.appendChild(bc);
        }
    }

    handleReturnNiveau() {
        this.setState({dipSelect: null, niveauView: true, diplomeView: false, ficheViewSelect: false,doubleFiche:false}, () => {
            this.breadCrumb()
        });
    }

    handleReturnDip() {
        this.setState({ ficheView: false, diplomeView: true,doubleFiche:false}, () => {
            this.setState({fiche: null})
            this.breadCrumb()
        });
    }

    handleReturnMetier() {
        window.location.assign('/nos-formations');
    }

    handleSelectNiveau(id, nom) {
        let {diplome} = this.state;
        let dip = diplome.filter(d => d.niv_id === id);
        this.setState({dipSelect: dip, niveauView: false, diplomeView: true, nameNiv: nom}, () => {
            this.breadCrumb()
        });
    }

    handleSelectDiplome(id, nom) {
        let {fiches} = this.state;
        let banner = document.querySelector('#banner');
        let fiche = fiches.filter(f => f.dip_id === id);

            if(fiche.length > 1){
                this.setState({fiche,doubleFiche:true,diplomeView:false})
            }else{
                fiche = fiche[0];
                let metier = document.location.href;
                window.location = `${metier}/${fiche.slug}`;
            }

    }
    handleSelectDiplome2(index){
        let {fiche} = this.state;
        let banner = document.querySelector('#banner');
        fiche = fiche[index];
        let metier = document.location.href;
        window.location = `${metier}/${fiche.slug}`;
    }

    render() {
        let {niveauView, diplomeView, ficheView, niveau, dipSelect, fiches,doubleFiche,fiche} = this.state;
        return (
            <>
                {
                    fiches === null ?
                        <>
                            <div className="return" onClick={this.handleReturnMetier}>
                                <p>retour</p>
                            </div>
                            <h2>Il y a pas de formations pour ce métier.</h2>
                        </>

                        :
                        <>
                            <div id="bc">
                                <a href="/nos-formations">Nos formations</a>
                                {
                                    diplomeView &&
                                    <>
                                        <span>.</span>
                                        <p onClick={this.handleReturnNiveau}>{this.state.nameNiv}</p>
                                    </>
                                }
                                {
                                    ficheView &&
                                    <>
                                        <span>.</span>
                                        <p onClick={this.handleReturnNiveau}>{this.state.nameNiv}</p>
                                        <span>.</span>
                                        <p onClick={this.handleReturnDip}>{this.state.nameDip}</p>
                                    </>
                                }

                                <span>.</span>
                                <p>{this.state.metier.nom}</p>
                            </div>

                            {
                                niveauView &&
                                <>
                                    <div className="return" onClick={this.handleReturnMetier}>
                                        <p>retour</p>
                                    </div>
                                    <div className="liste">
                                        {
                                            niveau.map((value, index) => (
                                                <div key={index} className="blockListe"
                                                     onClick={() => this.handleSelectNiveau(value.id, value.nom)}>
                                                    <a>
                                                        <h3 className="titleListe">{value.nom}</h3>
                                                        <p><img src="/public/images/iconArrowCircle.png" alt=""/></p>
                                                    </a>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                            }
                            {
                                diplomeView &&
                                <>
                                    <div className="return" onClick={this.handleReturnNiveau}>
                                        <p>retour</p>
                                    </div>
                                    <div className="liste">
                                        {
                                            dipSelect !== null &&
                                            dipSelect.map((value, index) => (
                                                <div key={index} className="blockListe"
                                                     onClick={() => this.handleSelectDiplome(value.id, value.nom)}>
                                                    <a>
                                                        <h3 className="titleListe">{value.nom}</h3>
                                                        <p><img src="/public/images/iconArrowCircle.png" alt=""/></p>
                                                    </a>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                            }
                            {
                                doubleFiche &&
                                <>
                                    <div className="return" onClick={this.handleReturnNiveau}>
                                        <p>retour</p>
                                    </div>
                                    <div className="liste">
                                        {
                                            fiche !== null &&
                                            fiche.map((value, index) => (
                                                <div key={index} className="blockListe"
                                                     onClick={() => this.handleSelectDiplome2(index)} >
                                                    <a>
                                                        <h3 className="titleListe">{value.nom}</h3>
                                                        <p><img src="/public/images/iconArrowCircle.png" alt=""/></p>
                                                    </a>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                            }
                            {
                                ficheView &&
                                <div>
                                    <div className="return" onClick={this.handleReturnDip}>
                                        <p>retour</p>
                                    </div>
                                    {
                                        this.state.fiche.miseajour !== null &&
                                        <div className="miseajour">
                                            <p className="miseajourfiche">Mise à jour de la fiche métier au <Moment
                                                format="DD/MM/YYYY">{this.state.fiche.miseajour}</Moment></p>
                                        </div>
                                    }
                                    {
                                        this.state.fiche.ou !== null &&
                                        <p>Lieu: { this.state.fiche.ou }</p>
                                    }
                                    <div className="fiche" dangerouslySetInnerHTML={{__html: this.state.fiche.body}}>

                                    </div>

                                    <h2>Chiffres clés</h2>
                                    <div className="chiffreCles" id="chiffreCles">
                                        <div className="block reussite">
                                            <p>Taux de réussite aux examens</p>
                                            <p>{this.state.fiche.examen}</p>
                                        </div>
                                    </div>
                                    {
                                        this.state.fiche.youtube !== null &&
                                        <div className="youtube" id="youtube">
                                            <div className="youtubeCenter"
                                                 dangerouslySetInnerHTML={{__html: this.state.fiche.youtube}}></div>
                                        </div>
                                    }
                                </div>
                            }
                        </>
                }
            </>
        )
    }
}
if (document.getElementById('formationSelect')) {
    let fiches = $('#formationSelect').data('fiches');
    let metier = $('#formationSelect').data('metier');
    let diplome = $('#formationSelect').data('diplome');
    let niveau = $('#formationSelect').data('niveau');
    ReactDOM.render(<FormationSelect fiches={fiches} metier={metier} diplome={diplome} niveau={niveau} />, document.getElementById('formationSelect'));
}
