import React from 'react';
import ReactDOM from 'react-dom';
import Moment from 'react-moment';


export default class Offredemplois extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            offrefull:this.props.offrefull,
            offres:this.props.offres,
            duree:this.props.duree,
            metiers:this.props.metiers,
            type:this.props.type,
            nb:this.props.nb,
            selectType:"type de contrat",
            selectDuree:"durée",
            selectSecteur:"secteur",
            selectNb:"nombre d'heure",
            nbPag:0,
        }
        this.handleChangeSelect = this.handleChangeSelect.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.pagination = this.pagination.bind(this)
    }

    componentDidMount() {
        //this.pagination();
    }
    pagination(){
        const {offres} = this.state;
        let count = offres.length;
        let nbPag = parseInt(count / 5) + 1;
        let tab = [];
        for (let i = 1 ; i <= nbPag ; i++){
            tab.push([]);
        }

            let j = 0;
            let test = false;
            offres.map((value,index) => {
                let modulo = index % 5;
                if(test === false && modulo === 0){
                    tab[j].push(value);
                    test = true;
                }else {
                    tab[j].push(value);
                    if(modulo === 4){
                        j++;
                    }
                }
            })

        this.setState({nbPag, offres:tab});
    }

    handleChangeSelect(value,select){
        if(select === "type"){
            this.setState({selectType:value},() => {
                this.changeListe();
            });
        }else if(select === "duree"){
            this.setState({selectDuree:value},() => {
                this.changeListe();
            });
        }else if(select === "secteur"){
            this.setState({selectSecteur:value},() => {
                this.changeListe();
            });
        }else if(select === "nb"){
            this.setState({selectNb:value},() => {
                this.changeListe();
            });
        }

    }
    changeListe(){
        const {selectType,selectDuree,selectSecteur,selectNb,offrefull,metiers} = this.state;
        let id = metiers.filter(m => m.nom === selectSecteur);
        let newListe = [];
        console.log(selectNb);
        /*
        newListe = offrefull.filter(of =>
            selectType !== "type de contrat" && of.contrat === selectType ||
            selectDuree !== "durée" && of.duree === selectDuree ||
            selectSecteur !== "secteur" && of.metier_id === id[0].id ||
            selectNb !== "nombre d'heure" && of.nbHeure === parseInt(selectNb)
        )*/
        if(selectType === "type de contrat" && selectDuree === "durée" && selectSecteur === "secteur" && selectNb === "nombre d'heure" ) {
            newListe = offrefull
        }else if(selectType !== "type de contrat" && selectDuree === "durée" && selectSecteur === "secteur" && selectNb === "nombre d'heure" ){
            newListe = offrefull.filter(of =>
                selectType !== "type de contrat" && of.contrat === selectType
            )
        }else if(selectType === "type de contrat" && selectDuree !== "durée" && selectSecteur === "secteur" && selectNb === "nombre d'heure" ){
            newListe = offrefull.filter(of =>
                selectDuree !== "durée" && of.duree === selectDuree
            )
        }else if(selectType === "type de contrat" && selectDuree === "durée" && selectSecteur !== "secteur" && selectNb === "nombre d'heure" ){
            newListe = offrefull.filter(of =>
                selectSecteur !== "secteur" && of.metier_id === id[0].id
            )
        }else if(selectType === "type de contrat" && selectDuree === "durée" && selectSecteur === "secteur" && selectNb !== "nombre d'heure" ) {
            newListe = offrefull.filter(of =>
                selectNb !== "nombre d'heure" && of.nbHeure === parseInt(selectNb)
            )
        }else if(selectType !== "type de contrat" && selectDuree !== "durée" && selectSecteur === "secteur" && selectNb === "nombre d'heure" ) {
            newListe = offrefull.filter(of =>
                selectType !== "type de contrat" && of.contrat === selectType &&
                selectDuree !== "durée" && of.duree === selectDuree
            )
        }else if(selectType !== "type de contrat" && selectDuree === "durée" && selectSecteur !== "secteur" && selectNb === "nombre d'heure" ) {
            newListe = offrefull.filter(of =>
                selectType !== "type de contrat" && of.contrat === selectType &&
                selectSecteur !== "secteur" && of.metier_id === id[0].id
            )
        }else if(selectType !== "type de contrat" && selectDuree === "durée" && selectSecteur === "secteur" && selectNb !== "nombre d'heure" ) {
            newListe = offrefull.filter(of =>
                selectType !== "type de contrat" && of.contrat === selectType &&
                selectNb !== "nombre d'heure" && of.nbHeure === parseInt(selectNb)
            )
        }else if(selectType === "type de contrat" && selectDuree !== "durée" && selectSecteur === "secteur" && selectNb !== "nombre d'heure" ) {
            newListe = offrefull.filter(of =>
                selectDuree !== "durée" && of.duree === selectDuree &&
                selectNb !== "nombre d'heure" && of.nbHeure === parseInt(selectNb)
            )
        }else if(selectType === "type de contrat" && selectDuree !== "durée" && selectSecteur !== "secteur" && selectNb === "nombre d'heure" ) {
            newListe = offrefull.filter(of =>
                selectDuree !== "durée" && of.duree === selectDuree &&
                selectSecteur !== "secteur" && of.metier_id === id[0].id
            )
        }else if(selectType === "type de contrat" && selectDuree === "durée" && selectSecteur !== "secteur" && selectNb !== "nombre d'heure" ) {
            newListe = offrefull.filter(of =>
                selectSecteur !== "secteur" && of.metier_id === id[0].id &&
                selectNb !== "nombre d'heure" && of.nbHeure === parseInt(selectNb)
            )
        }else if(selectType !== "type de contrat" && selectDuree !== "durée" && selectSecteur !== "secteur" && selectNb === "nombre d'heure" ) {
            console.log('test');
            newListe = offrefull.filter(of =>
                selectType !== "type de contrat" && of.contrat === selectType &&
                selectDuree !== "durée" && of.duree === selectDuree &&
                selectSecteur !== "secteur" && of.metier_id === id[0].id
            )
        }else if(selectType !== "type de contrat" && selectDuree !== "durée" && selectSecteur === "secteur" && selectNb !== "nombre d'heure" ) {
            newListe = offrefull.filter(of =>
                selectType !== "type de contrat" && of.contrat === selectType &&
                selectDuree !== "durée" && of.duree === selectDuree &&
                selectNb !== "nombre d'heure" && of.nbHeure === parseInt(selectNb)
            )
        }else if(selectType !== "type de contrat" && selectDuree === "durée" && selectSecteur !== "secteur" && selectNb !== "nombre d'heure" ) {
            newListe = offrefull.filter(of =>
                selectType !== "type de contrat" && of.contrat === selectType &&
                selectSecteur !== "secteur" && of.metier_id === id[0].id &&
                selectNb !== "nombre d'heure" && of.nbHeure === parseInt(selectNb)
            )
        }else if(selectType === "type de contrat" && selectDuree !== "durée" && selectSecteur !== "secteur" && selectNb !== "nombre d'heure" ) {
            newListe = offrefull.filter(of =>
                selectDuree !== "durée" && of.duree === selectDuree &&
                selectSecteur !== "secteur" && of.metier_id === id[0].id &&
                selectNb !== "nombre d'heure" && of.nbHeure === parseInt(selectNb)
            )
        }else if(selectType !== "type de contrat" && selectDuree !== "durée" && selectSecteur !== "secteur" && selectNb !== "nombre d'heure" ) {
            newListe = offrefull.filter(of =>
                selectType !== "type de contrat" && of.contrat === selectType &&
                selectDuree !== "durée" && of.duree === selectDuree &&
                selectSecteur !== "secteur" && of.metier_id === id[0].id &&
                selectNb !== "nombre d'heure" && of.nbHeure === parseInt(selectNb)
            )
        }
        if(newListe[0] === undefined){
            if(selectType === "type de contrat" && selectDuree === "durée" && selectSecteur === "secteur" && selectNb == "nombre d'heure"){
            console.log('test unde')
                newListe = offrefull
            }
        }
        this.setState({offres:newListe},() => {
            //this.pagination();
        });

    }
    handleOpen(e){
        if(e.target.tagName === "P"){
            let body = e.target.nextSibling;
            let arrow = e.target.childNodes[1].childNodes[0];
            if(arrow.style.transform === "rotate(0deg)"){
                arrow.style.transform = "rotate(90deg)";
                body.style.height = "0"
                body.style.marginBottom = "0px"
            }else{
                arrow.style.transform = "rotate(0deg)";
                body.style.height = "auto"
                body.style.marginBottom = "25px"
            }
        }else{
            let body = e.target.parentNode.parentNode.nextSibling;
            let arrow = e.target;
            if(arrow.style.transform === "rotate(0deg)"){
                arrow.style.transform = "rotate(90deg)";
                body.style.height = "0"
                body.style.marginBottom = "0px"
            }else{
                arrow.style.transform = "rotate(0deg)";
                body.style.height = "auto"
                body.style.marginBottom = "25px"
            }
        }
    }
    render() {
        let {offres,type,duree,nb,metiers} = this.state;
        return (
            <>
                <div className="title">
                    <h2>Nos offres</h2>
                </div>
                <div className="filter">
                    <select name="contrat" id="contrat" onChange={(e) => this.handleChangeSelect(e.target.value,"type")}>
                        <option value="type de contrat">type de contrat</option>
                        {
                            type.map((value,index) => (
                                <option key={index} value={value.contrat}>{value.contrat}</option>
                            ))
                        }
                    </select>
                    <select name="contrat" id="contrat" onChange={(e) => this.handleChangeSelect(e.target.value,"duree")}>
                        <option value="durée">durée</option>
                        {
                            duree.map((value,index) => (
                                <option key={index} value={value.duree}>{value.duree}</option>
                            ))
                        }
                    </select>
                    <select name="contrat" id="contrat" onChange={(e) => this.handleChangeSelect(e.target.value,"secteur")}>
                        <option value="secteur">secteur</option>
                        {
                            metiers.map((value,index) => (
                                <option key={index} value={value.nom}>{value.nom}</option>
                            ))
                        }
                    </select>
                    <select name="contrat" id="contrat" onChange={(e) => this.handleChangeSelect(e.target.value,"nb")}>
                        <option value="nombre d'heure">nombre d'heure</option>
                        {
                            nb.map((value,index) => (
                                <option key={index} value={value.nbHeure}>{value.nbHeure}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="blockOffre">
                    {
                        offres.map((value,index) => (
                            <div key={index} className="offreListe">
                                <div className="center">
                                <h3>{value.titre} <span>{value.lieu}</span></h3>
                                    <p className="dateOffre">Date de l'offre: <Moment format="DD/MM/YYYY">{value.created_at}</Moment></p>
                                <div className="resume" dangerouslySetInnerHTML={{__html: value.resume}}></div>
                                <p className="savoir" onClick={(e) => this.handleOpen(e)}>En savoir plus <span><img src="/public/images/arrow.svg" alt="fléche"/></span></p>
                                <div className="body">
                                    <p>Type de contrat:{value.contrat}, Durée du contrat: {value.duree} </p>
                                    <div dangerouslySetInnerHTML={{__html: value.savoir_plus}}></div>
                                </div>
                                <div className="contact" >
                                    <p>Si vous êtes intéressé, merci de nous contacter  {value.contact !== null ? "par téléphone au "+value.contact+" ou" : null} par e-mail <a
                                        href="mailto:{value.email}">{value.email}</a></p>
                                </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </>
        )
    }
}
if (document.getElementById('offredemplois')) {
    let offres = $('#offredemplois').data('offres');
    let offrefull = $('#offredemplois').data('offrefull');
    let duree = $('#offredemplois').data('duree');
    let metiers = $('#offredemplois').data('metiers');
    let type = $('#offredemplois').data('type');
    let nb = $('#offredemplois').data('nb');
    ReactDOM.render(<Offredemplois  offres={offres} offrefull={offrefull} duree={duree} metiers={metiers} type={type} nb={nb}/>, document.getElementById('offredemplois'));
}
